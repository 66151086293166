import { jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import useLocales from './useLocales';
export default function ThemeLocalization({ children }) {
    const outerTheme = useTheme();
    const { currentLang } = useLocales();
    const theme = createTheme(outerTheme, currentLang.systemValue);
    return _jsxs(ThemeProvider, { theme: theme, children: [" ", children, " "] });
}
