// @mui
import { enUS } from '@mui/material/locale';
import { frFR } from '@mui/x-date-pickers/locales';
import LondonFlag from '../assets/icons/flags/ic_flag_en.svg';
import FranceFlag from '../assets/icons/flags/ic_flag_fr.svg';
// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------
export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: LondonFlag,
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: FranceFlag,
    },
];
export const defaultLang = allLangs.find((_lang) => navigator.language.split('-')[0].includes(_lang.value)) || allLangs[1];
